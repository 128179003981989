import CONFIG from "./config.json";

var env = {}

function configEnv() {
    env = CONFIG;
}

export function getEnvironment() {
    configEnv();
    return env.env;
}

export function getDomain() {
    configEnv();
    return env.domain;
}

export function getClientId() {
    configEnv();
    return env.clientId;
}

export function getAudience() {
    configEnv();
    return env.audience;
}

export function getAIAnalysisBlobUrl() {
    configEnv();
    return env.imgAiAnalysisBlobUrl;
}

export function getStorageUrl() {
    configEnv();
    return env.storageUrl;
}

export function getAuth0UserUrl() {
    configEnv();
    return env.auth0UserUrl;
}

export function getPowerBIReportId() {
    configEnv();
    return env.campaignPowerBIReportId;
}

export function getVisitsReportBaseURL() {
    configEnv();
    return env.visitsReportBaseURL;
}

export function getGoogleSearchBaseURL() {
    configEnv();
    return env.googleSearchBaseURL;
}

export function getGoogleSearchAPIKey() {
    configEnv();
    return env.googleSearchAPIKey;
}

export function getGoogleSearchEngineKey() {
    configEnv();
    return env.googleSearchEngineKey;
}

