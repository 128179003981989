import React, { Fragment, useState } from 'react';
import axios from "axios";

import { LOCAL_STORAGE_FILTER_KEYS, LOCAL_STORAGE_TABLE_CONFIG_KEYS, saveFilterConfig } from '../../components/common/filters/filters-helper.js';
import DynamicFilter from '../../components/common/filters/dynamic_filter';
import JsonModal from '../../pages/visits/components/details/json-modal';
import { productsColumns as columns } from "../../utils/tablesHelper";
import ImportButton from '../../components/common/buttons/import';
import { Alert } from "../../components/common/modals/modals.js";
import UploadModal from '../../components/common/upload/modal';
import Breadcrumb from '../../components/common/breadcrumb';
import emptyImage from '../../assets/images/emptylist.png';
import { updateFilterValues } from "../../utils/helper";
import { ToastContainer, toast } from "react-toastify";
import Datatable from "../../components/tables/table";
import { useService } from "../../hooks/useService";
import { Edit3 as Edit } from 'react-feather';
import { productFilters } from './filters';

import { useAuth0 } from "@auth0/auth0-react";

const ProductsList = ({ history }) => {
    const { getAccessTokenSilently } = useAuth0();
    const service = useService();

    //JSON Modal
    const [showJsonModal, setShowJsonModal] = React.useState(false);
    const [jsonModalProductId, setJsonModalProductId] = React.useState('');

    const toggleJsonModal = (productId) => {
        setJsonModalProductId(productId);
        setShowJsonModal(!showJsonModal);
    }

    // import modal configuration
    const [uploadModal, setUploadModal] = useState();
    const [modalData, setModalData] = useState(undefined);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(undefined);
    const [init, setInit] = React.useState(true);

    const [loadingTable, setLoadingTable] = useState(true);
    const localStorageFilterKey = LOCAL_STORAGE_FILTER_KEYS.PRODUCTS;
    const localStorageTableConfigKey = LOCAL_STORAGE_TABLE_CONFIG_KEYS.PRODUCTS;
    const [data, setData] = React.useState([]);
    const [currentPage, setCurrentPage] = React.useState(0);
    const [sortColumn, setSortColumn] = React.useState(null);
    const [sortDirection, setSortDirection] = React.useState(0);
    const [firstLoad, setFirstLoad] = React.useState(true);
    const [pageSize, setPageSize] = React.useState(10);
    const [recordsFiltered, setRecordsFiltered] = React.useState(0);
    const [tableFilters, setTableFilters] = React.useState([...productFilters]);

    const toggleUploadModal = () => {
        setUploadModal(!uploadModal);
        setModalData(undefined);
        setLoading(false);
        setError(undefined);
        setInit(true);
    }

    const changeActiveStatus = async (product, index) => {

        await service.post(`/api/product/active/${product.id}/${!product.isActive}`)
            .then(response => {
                if (response.status === 200) {
                    let items = [...data];

                    let item = items.find((it) => it.id == product.id);
                    product.active = !product.active;
                    product.isActive = !product.isActive;
                    items[index] = item;
                    setData(items);

                    let change = !product.isActive ? "inativado" : "ativado";
                    toast.success("Produto " + change + " com sucesso!");
                } else {
                    let change = !product.isActive ? "inativar" : "ativar";
                    toast.error("Não foi possível " + change + " o produto");
                }
            })
    }

    const importProducts = async(file) => {
        setLoading(true);
        var formData = new FormData();
        formData.append("file", file);
        var accessToken = await getAccessTokenSilently();

        axios.put(`/api/Product/Import`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${accessToken}`
              }
        })
        .then(response => {
            setLoading(false);
            if(response.data.status === 200){
                setModalData(response.data.data)
            }else{
                setError(response.data.title);
            }
            setInit(false);
          })
        .catch(error => {
            setUploadModal(false);
            Alert("Não foi possível completar sua solicitação", "", "error");
        })
      };

      const getProducts = async (page = 0, currentPageSize = 10, sortColumnFilter = null, orderDirection = 0, nameFilter = null, initialFilters) => {

        if (nameFilter === "")
            nameFilter = null;

        if (nameFilter != null)
            setSortColumn(null);

        setSortColumn(sortColumnFilter);
        setSortDirection(orderDirection);

        setPageSize(currentPageSize);
        setCurrentPage(page);

        saveFilterConfig(sortColumnFilter, orderDirection, page, currentPageSize, LOCAL_STORAGE_TABLE_CONFIG_KEYS.PRODUCTS);

        var filters = [];

        const filterItems = initialFilters ?? tableFilters;

        filterItems.map(filter => {            
            if(filter.value !== '' && filter.value != null && filter.value !== -1 && filter.value !== '-1') {
                filters.push({
                    name: filter.name,
                    value: filter.value
                })
            }
        })

        var skip = 0;

        if(!isNaN(page))
            skip = page * (currentPageSize !== null ? currentPageSize : pageSize);

        if (skip === 0) {
            setCurrentPage(0);

            if(filters.length > 0) {
                setLoadingTable(true);
            }
        }

        var payload = {
            "sortColumn": sortColumn,
            "sortColumnDirection": orderDirection,
            "searchValue": nameFilter,
            "pageSize": currentPageSize !== null ? currentPageSize : pageSize,
            "skip": skip,
            "isActive": null,
            "filters": filters
        }

        await service.post("/api/product/list", payload)
        .then(async response => {
            if (response?.data?.data === null) {
                return await Alert("", "Falha ao buscar os dados", "error");
            }

            var items = response?.data?.data?.data.map((c, index) => {
                return {
                    id: c.id,
                    name: c.name,
                    isActive: c.isActive,
                    active: c.isActive,
                    registerDate: new Date(c.registerDate).toLocaleDateString('pt-BR'),
                    expirationDate: new Date(c.expirationDate).toLocaleDateString('pt-BR'),
                    EAN: c.ean,
                    brand: c.brand,
                    category: c.category,
                    manufacturer: c.manufacturer,
                    segment: c.segment,
                    subSegment: c.subSegment,
                    idealPrice: c.idealPrice,
                    priceOutlierFactor: c.priceOutlierFactor,
                    priceRangeFactor: c.priceRangeFactor,
                    aIFilterKeywords: c.aiFilterKeywords ? c.aiFilterKeywords.split(';').map(keyword => (<span className="main-color-tag mr-3" key={keyword}>{keyword}</span>)) : '',
                    aISearchKeywords: <p className='pointer' onClick={() => toggleJsonModal(c.id)}>{c.aiSearchKeywordsJSON?.length > 15 ? c.aiSearchKeywordsJSON.substring(0, 15) : ''}</p>,
                    aISearchKeywordsJSON: JSON.parse(c.aiSearchKeywordsJSON),
                    details: <div className="align-center pointer" onClick={() => {history.push(`/product/${c.id}`)}}><Edit /></div>
                }
            })

            setData(items);
            if(response?.data?.data?.recordsFiltered)
             setRecordsFiltered(response.data.data.recordsFiltered);

        if (firstLoad)
            setFirstLoad(false);
            

        }).finally(() => {
            setLoadingTable(false);
        });
    }

    const handleFilterReset = async() => {
        setLoadingTable(true);

        localStorage.removeItem(localStorageFilterKey);
        localStorage.removeItem(localStorageTableConfigKey);

        setTableFilters(tableFilters.map(t => {
            t.value = '';
            return t;
        }))

        setCurrentPage(0);
        setSortDirection(0);
        setSortColumn(null);

        getProducts();
        setLoadingTable(false);
    }

    React.useEffect(() => {

        async function init() {
            const filtersResult = await localStorage.getItem(localStorageFilterKey); 

            var filters = [];
            if(filtersResult != null) {
                const resultFilters = JSON.parse(filtersResult);
                filters = updateFilterValues(productFilters, resultFilters);
                setTableFilters(filters);  
            }

            var tableConfigResult = localStorage.getItem(localStorageTableConfigKey);
            var page = 0;
            var pageSize = 10;
            var sortColumn = null;
            var sortDirection = 0;

            if(tableConfigResult != null) {

                var tableConfig;
                if(tableConfigResult)
                    tableConfig = JSON.parse(tableConfigResult);
    
                if(tableConfig?.page){
                    setCurrentPage(tableConfig.page);
                    page = tableConfig.page;
                }
    
                if(tableConfig?.pageSize) {
                    setPageSize(tableConfig.pageSize);
                    pageSize = tableConfig.pageSize;
                }
                
                if(tableConfig?.ordenation?.sortColumn) {
                    setSortColumn(tableConfig.ordenation.sortColumn);
                    sortColumn = tableConfig.ordenation.sortColumn;
                }
    
                if(tableConfig?.ordenation?.sortDirection) {
                    setSortDirection(tableConfig.ordenation.sortDirection);
                    sortDirection = tableConfig.ordenation.sortDirection;
                }
            }

            await getProducts(page, pageSize, sortColumn, sortDirection, null, filters);
        }

        init();
    }, []);

    
    return (
        <Fragment>
            <Breadcrumb title="Produtos" />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-header">
                                <div className="title-btn">
                                    <span className="d-flex">
                                        <h5>Listagem de Produtos</h5>
                                        <p className="pl-10 pt-3">({recordsFiltered} itens)</p>
                                    </span>
                                    <div>
                                        <ImportButton value="Produtos" onclick={toggleUploadModal} />                                
                                    </div>
                                </div>
                                <DynamicFilter 
                                    handleSearch={getProducts}
                                    filters={tableFilters}
                                    localStorageName={localStorageFilterKey}
                                    handleReset={handleFilterReset}
                                    handleFilterChange={setTableFilters}
                                    saveFilterState={saveFilterConfig}
                                />
                            </div>
                            
                            {!loadingTable && data.length === 0 && (
                                <div className="align-center">
                                    <div className="empty-list-txt">Nenhum produto encontrado</div>
                                    <img className="empty-list-img" src={emptyImage} alt="empty list" />
                                </div>
                            )}

                            {loadingTable &&
                                <div className="loader-box loading" style={{minHeight: '50vh'}}>
                                    <div className="loader">
                                        <div className="line bg-primary"></div>
                                        <div className="line bg-primary"></div>
                                        <div className="line bg-primary"></div>
                                        <div className="line bg-primary"></div>
                                    </div>
                                </div>
                            }

                            {!loadingTable && data.length > 0 && <div className="card-body datatable-react">
                                <Datatable
                                    multiSelectOption={false}
                                    myData={data}
                                    loading={loadingTable}
                                    firstLoad={firstLoad}
                                    pageSize={pageSize}
                                    pagination={true}
                                    class="-striped -highlight"
                                    columns={columns}
                                    totalPages={Math.ceil(recordsFiltered/pageSize)}
                                    setPageSize={setPageSize}
                                    onPageChange={getProducts}
                                    selectedData={null}
                                    setCurrentPage={setCurrentPage}
                                    currentPage={currentPage}
                                    changeStatus={changeActiveStatus}
                                    manual={true}
                                />
                            </div>}
                            
                            <UploadModal value="Produtos" isOpen={uploadModal} loading={loading} toggle={toggleUploadModal} sendImport={importProducts} error={error} data={modalData} init={init} setInit={setInit} />
                        </div>


                    </div>
                </div>
            </div>

            <JsonModal 
                value={data?.find(p => p.id == jsonModalProductId)?.name}
                isOpen={showJsonModal}
                toggle={toggleJsonModal}
                jsonContent={data?.find(p => p.id == jsonModalProductId)?.aISearchKeywordsJSON}
            />

            <ToastContainer />
        </Fragment>
    );
};

export default ProductsList;