import React, {useState} from "react";
import {sortableContainer, sortableElement, sortableHandle} from 'react-sortable-hoc';
import arrayMove from 'array-move';
import Survey from './survey';

const SortableContainer = sortableContainer(({children}) => {
return <ul style={{paddingLeft: 0}}>{children}</ul>;
});

const DragHandle = sortableHandle(() => <div className="dragArea">
  <img className="sort-icon" src={require("../../../assets/images/sort.svg")} alt="sort surveys" />
</div>);

const SortableItem = sortableElement(({activeKey, setActiveKey, key, item, saveStep, removeItem, canEdit, handleChange }) => (
    <div className="flex"  key={key}>
        <DragHandle />
        <Survey activeKey={activeKey} setActiveKey={setActiveKey} item={item} saveStep={saveStep} removeItem={removeItem} handleChange={handleChange} />
    </div>
));


const Surveys = ({items, setItems}) => {
    
    const [activeKey, setActiveKey] = useState(-1);

    const removeItem = (itemId) => {
    
        let i = items.filter((item) => item.id !== itemId);
    
        for(let j = 0; j < i.length; j++){  
            if(i[j].number != j +1)
            i[j].number = j +1;
        }
    
        setItems([...i])    
    }
    
    const onSortEnd = ({oldIndex, newIndex}) => {
        let sortItems = arrayMove(items, oldIndex, newIndex);

        let minValue = oldIndex < newIndex ? oldIndex : newIndex;
        let maxValue = oldIndex > newIndex ? oldIndex : newIndex;

        for(let i = minValue; i <= maxValue; i++){
            sortItems[i].number = i +1;
        }
        setItems(sortItems);
    };

    function handleOnChange({target}) {
    const { id, value } = target;
    const field = id.split('-')[0];
    const itemId = id.substring(field.length +1);

    let item = items.filter((item) => item.id === itemId)[0];
    var index = items.indexOf(item);
    var i = items;
    i[index][field] = value;
    setItems([...i])
    }

    return (
        <div>
            <SortableContainer onSortEnd={onSortEnd} pressDelay={0} useDragHandle>
                {items.map((value, index) => (
                    <SortableItem activeKey={activeKey} setActiveKey={setActiveKey} key={`survey-item-${value.id}`} index={index} setStep={setItems} item={value} handleChange={handleOnChange} removeItem={removeItem} />
                ))}
            </SortableContainer>
        </div>
    )

}

export default Surveys;