import React, { useState, useEffect } from "react";
import ImageZoom from './image-zoom';
import './image-search-gallery.css';

const ImageSearchGallery = ({ imageUrls = [] }) => {
    const [currentIndex, setCurrentIndex] = useState(-1);

    useEffect(() => {
        if (imageUrls?.length > 0) {
            setCurrentIndex(0);
        }
    }, [imageUrls]);

    const openUrl = (url) => { window.open(url, '_blank'); };

    return (
        <div className="image-search-view-container">
            {currentIndex >= 0 && <ImageZoom height="25vw" src={imageUrls[currentIndex]} />}

            <div className="image-search-thumbnail-container">
                {imageUrls
                    .map((url, index) => (
                        <img
                            key={index}
                            className={`${index === currentIndex ? 'image-search-view-thumbnail-selected':'image-search-view-thumbnail'}`}
                            src={url}
                            alt={`Thumbnail ${index + 1}`}
                            onClick={() => openUrl(url)}
                            onMouseMove={() => setCurrentIndex(index)}
                        />
                    ))}
            </div>
        </div>
    );
}

export default ImageSearchGallery;