import { getEnvironment } from "../../../config";

export const LOCAL_STORAGE_FILTER_KEYS = {
    PRODUCTS: `products-filter-${getEnvironment()}`,
    VISIT_RESERVATIONS: `visit-reservations-filter-${getEnvironment()}`
}

export const LOCAL_STORAGE_TABLE_CONFIG_KEYS = {
    PRODUCTS:  `products-table-config-${getEnvironment()}`,
    VISIT_RESERVATIONS:  `visits-reservation-config-${getEnvironment()}`
}

export const saveFilterConfig = (sortColumn, orderDirection, currentPage, pageSize, localStorageTableConfigKey) => {
    const tableConfig = {
        page: currentPage,
        pageSize: pageSize,
        ordenation: {
            sortColumn: sortColumn,
            sortDirection: orderDirection
        }
    }

    localStorage.setItem(localStorageTableConfigKey, JSON.stringify(tableConfig));
}
