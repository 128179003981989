import React, { Fragment, useEffect, useState } from 'react'
import { getGoogleSearchAPIKey, getGoogleSearchBaseURL, getGoogleSearchEngineKey } from "../config.js";
import axios from "axios";

const useGoogleSearch = (query) => {
    const [googleSearchResults, setGoogleSearchResults] = useState([]);
    const BASE_URL = getGoogleSearchBaseURL();
    const API_KEY = getGoogleSearchAPIKey();
    const ENGINE_KEY = getGoogleSearchEngineKey();
    const amountOfResults = 6;

    useEffect(() => {
        const fetchData = async () => {
            const response = await axios.get(`${BASE_URL}?key=${API_KEY}&cx=${ENGINE_KEY}&q=${query}&num=${amountOfResults}`);
            var images = []

            for (let i = 0; i < response?.data?.items?.length; i++) {
                var availableImages = response.data.items[i]?.pagemap?.cse_image;

                if (availableImages?.length > 0) {
                    images.push(availableImages[0].src)
                }
            }            
            setGoogleSearchResults(images);
        };
        if(query !== null && query !== "") {
            fetchData();
        }
    }, [query]);
    return {
        googleSearchResults
    }
}

export default useGoogleSearch;