import React from 'react';
import { Trash2 } from 'react-feather';

const Survey = ({item, handleChange, removeItem }) => {

    return (
        <div className="flex">
            <div className="field-filter-item">
                <label className="label-filter">Pergunta</label>
                <br/>                
                <textarea className="form-control w-35vw" type="text" placeholder="Pergunta" value={item.title} onChange={handleChange} id={"title-" + item.id} />
            </div>

            <div className="field-filter-item">
                <label className="label-filter">Formato resposta</label>
                <br/>
                <select style={{width: '200px'}} value={item.answerType} onChange={handleChange} id={"answerType-" + item.id}>
                    <option value="0">Texto</option>
                    <option value="1">Câmera</option>
                    <option value="2">Decimal com S/N</option>
                    <option value="3">Sim/Não</option>
                    <option value="4">QRCode</option>
                    <option value="5">Código de barras</option>
                    <option value="6">Câmera - a granel</option>
                </select>
            </div>

            <div className="field-filter-item">
                <label className="label-filter">Palavras-chave IA</label>
                <br/>                
                <input className="form-control" type="text" placeholder="item1;item2;item3..." value={item.AIFilterKeywords} onChange={handleChange} id={"AIFilterKeywords-" + item.id} />
            </div>

            <div className="field-filter-item remove-item" onClick={() => removeItem(item.id)}>
                <Trash2/>
            </div>
        </div>
      );
}

export default Survey;