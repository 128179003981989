import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Input from '../../../../components/common/form/input';
import "react-table/react-table.css";

const RoutePresetConfirmationModal = ({toggle, isOpen, handleCreatePreset}) => {

    const [loading, setLoading] = React.useState(false);
    const [presetName, setPresetName] = React.useState("");
    const [presetNameError, setPresetNameError] = React.useState(false);

    React.useEffect(() => {
        setPresetName("");
        setPresetNameError(false);
    }, [isOpen]);

    const createPreset = async () => {
        if(loading)
            return;

        if(presetName === '' || presetName === null || presetName === undefined){
            setPresetNameError('Uma identificação para o Preset de Combo deve ser fornecida.');
            return;
        }

        setLoading(true);
        await handleCreatePreset(presetName);
        setLoading(false);
    }

    return (
        <>
            <Modal isOpen={isOpen} toggle={toggle} className="modal-body" centered={true} style={{ maxWidth: '50%' }}>
                <ModalHeader toggle={toggle}>Cadastrar novo Preset de Combo</ModalHeader>
                <ModalBody>
                    <div className='flex space-between'>                        
                        <Input label='Nome' type='text' name='name' required={true} value={presetName} maxLength={50} onChange={({target}) => setPresetName(target.value)} inputClassName='w-20vw' breakLabelLine={true} />
                        {presetNameError && <p className='error-field'>{presetNameError}</p>}
                    </div>
                </ModalBody>

                {loading && <div className="progress">
                    <div className="progress-bar-animated bg-primary progress-bar-striped" role="progressbar" style={{ width: "100%" }} aria-valuenow="85" aria-valuemin="0" aria-valuemax="100"></div>
                </div> }

                <ModalFooter className="modal-footer">
                    <button disabled={loading} className="btn btn-pill btn-danger" type="button" onClick={toggle}>Cancelar</button>
                    <button disabled={loading} className="btn btn-pill btn-primary btn-air-primary" type="button" onClick={createPreset}>Cadastrar</button>
                </ModalFooter>
            </Modal>
            
        </>
    );
}

export default RoutePresetConfirmationModal;